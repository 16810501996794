import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

//prod
// const firebaseConfig = {
//   apiKey: "AIzaSyDlz3fheKoFQMNanvJBwYYcgTVIT1H1II4",
//   authDomain: "decode-cc88c.firebaseapp.com",
//   projectId: "decode-cc88c",
//   storageBucket: "decode-cc88c.appspot.com",
//   messagingSenderId: "87306967899",
//   appId: "1:87306967899:web:0242c37f43017f3382187e",
//   measurementId: "G-T7821TH09X",
// };

//staging
// const firebaseConfig = {
//   apiKey: "AIzaSyCRplYkeU42DpX-AW8FYvnaxyPmzfS_VBQ",
//   authDomain: "toroscience-uat.firebaseapp.com",
//   projectId: "toroscience-uat",
//   storageBucket: "toroscience-uat.appspot.com",
//   messagingSenderId: "1052398853253",
//   appId: "1:1052398853253:web:fd155ca229bd8d54c11dec",
//   measurementId: "G-JQEGT2N1PX",
// };

//dev
const firebaseConfig = {
  apiKey: "AIzaSyDQGGLoGmU-vLPPb2QtihpJ-ehOYXWpR-U",
  authDomain: "toroscience-93c4c.firebaseapp.com",
  projectId: "toroscience-93c4c",
  storageBucket: "toroscience-93c4c.appspot.com",
  messagingSenderId: "547321940476",
  appId: "1:547321940476:web:0b0fa2c06f07482d51ca79",
  measurementId: "G-2KEEP2LZ01",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { db, app, auth };
